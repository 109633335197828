import React from "react"
import './footer.css'

const Footer = () => {
    return (
        <div>
            <div className="footer--text">Made by Maanas Gopi - 2024</div>
        </div>
    )
}

export default Footer;